<main *ngIf="{showPassword: showPassword$ | async} as state" class="content legacy-login">
  <mat-card>
    <div *ngIf="errorMsg">
      <small data-test="login-form-error">{{ errorMsg }}</small>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label for="username">Username</mat-label>
          <input
            matInput
            type="text"
            formControlName="username"
            class="form-control"
            data-test="login-form-username"
            [class.is-invalid]="submitted && f.username.errors"
          />
          <mat-hint *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <small *ngIf="f.username.errors.required">Username is required</small>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="form-group password">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label for="password">Password</mat-label>
          <input
            *ngIf="state.showPassword"
            autocomplete="off"
            matInput
            type="text"
            formControlName="password"
            class="form-control"
            data-test="login-form-password"
            [class.is-invalid]="submitted && f.password.errors"
          />
          <input
            *ngIf="!state.showPassword"
            autocomplete="off"
            matInput
            type="password"
            formControlName="password"
            class="form-control"
            data-test="login-form-password"
            [class.is-invalid]="submitted && f.password.errors"
          />
          <oph-icon
            [name]="state.showPassword ? 'show-password' : 'hide-password'"
            class="cursor-pointer"
            (click)="onTogglePassword($event)"
            matSuffix
          ></oph-icon>
          <mat-hint *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <small *ngIf="f.password.errors.required">Password is required</small>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="form-group text-right">
        <button
          mat-raised-button
          color="primary"
          [disabled]="loading"
          class="btn btn-primary login-btn"
          data-test="login-form-submit-button"
        >
          Log In
          <img
            *ngIf="loading"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </button>
      </div>
    </form>
  </mat-card>
</main>
