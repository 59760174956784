<a>
  <button mat-icon-button routerLink="/dashboard">
    <img src="assets/img/icons/bottom-nav/home-active.svg" /></button
></a>

<a
  ><button mat-icon-button routerLink="/dashboard/current-sked">
    <img src="assets/img/icons/bottom-nav/icon-2.svg" /></button
></a>

<a
  ><button mat-icon-button routerLink="/paragraphs">
    <img src="assets/img/icons/bottom-nav/paragraphs.svg" /></button
></a>

<button mat-icon-button (click)="onOpen()">
  <img src="assets/img/icons/bottom-nav/more.svg" />
</button>

<img class="left-corner" src="assets/img/icons/bottom-nav/corner.svg" />

<img class="right-corner" src="assets/img/icons/bottom-nav/corner.svg" />
