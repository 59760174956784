<div>
  <h1>Abandon?</h1>
  <span
    >If you proceed, changes made to this <br />
    Chapter will be lost.</span
  >
</div>

<div class="button-container">
  <dialog-cancel-button></dialog-cancel-button>
  <button class="use-button" mat-flat-button color="primary" type="button" (click)="onAbandonTemplateClick()">
    Abandon
  </button>
</div>
